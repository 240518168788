import { css } from "@emotion/core"
import { colors, radius } from "css/theme"

export const title = css`
  font-size: 40px;
  color: #323648;
  font-weight: bold;
  font-family: var(--heading);
  line-height: 1.45;
  margin-bottom: 1rem;
  @media (max-width: 767px) {
    font-size: 2rem;
  }
  @media (max-width: 479px) {
    font-size: 1.5rem;
  }
`

export const byLine = css`
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 0.89px;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.articleByline};
  font-family: var(--heading);
`

export const topRow = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const socialLinks = css`
  display: flex;
  align-items: center;
  svg {
    margin-left: 0.5rem;
    cursor: pointer;
    fill: ${colors.socialLinkColor};
    transition: fill 0.3s ease;
  }
  svg:hover {
    fill: ${colors.background};
  }
`

export const imageWrapper = css`
  text-align: center;
  overflow: hidden;
  border-radius: ${radius.big};
  img {
    width: 100%;
    display: block;
  }
  margin-bottom: 3rem;
`

export const subtitle = css`
  font-size: 1.3rem;
  margin-top: 2rem;
  display: block;
  margin-bottom: 2rem;
`

export const content = css`
  font-size: 21px;
  line-height: 34px;
  opacity: 0.9;
  margin-bottom: 3rem;
  @media (max-width: 479px) {
    margin-top: 2rem;
    font-size: 18px;
    margin-bottom: 0;
  }
  // Other tags to code for
  h2 {
    font-size: 2rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
    @media (max-width: 479px) {
      font-size: 1.5rem;
    }
  }
  h3 {
    font-size: 1.75rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    @media (max-width: 479px) {
      font-size: 1.3rem;
    }
  }
  h4 {
    font-size: 1.5rem;
    margin-top: 2rem;
    line-height: 1.5;
    margin-bottom: 2rem;
    @media (max-width: 479px) {
      font-size: 1.1rem;
    }
  }

  figcaption {
    font-style: italic;
    font-size: 17px;
  }

  .wp-block-button__link {
    display: inline-block;
    padding: 8px 2rem;
    color: black !important;
    border-radius: 4px;
    font-size: 17px;
    background-color: #febc10;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  a {
    color: ${colors.orange};
  }
  blockquote {
    margin-left: 0;
    margin-right: 1.78rem;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 1.44625rem;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.78rem;
    line-height: 1.78;
    color: ${colors.blockquoteText};
    font-style: italic;
    border-left: 0.33375rem solid ${colors.blockquoteBorder};
  }
  video {
    max-width: 100%;
  }
  .aligncenter {
    text-align: center;
  }
  ol,
  ul {
    padding-left: 2rem;
  }
  iframe {
    max-width: 100%;
  }
  img {
    height: auto;
  }
  @media (max-width: 576px) {
    ol,
    ul {
      padding-left: 1rem;
    }
  }
`
