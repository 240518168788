import { css } from "@emotion/core"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import FacebookIcon from "images/svg/icons/facebook.svg"
import TwitterIcon from "images/svg/icons/twitter.svg"
import React from "react"
import { If } from "src/components"
import PageLayout from "src/components/PageLayout"
import * as article from "src/css/article"
import "src/queries/featured_media"
import { Banner } from "src/views/blog/components"
import DraftLabel from "../components/DraftLabel"
import SEO from "../components/seo"
import { BlogStructuredData } from "../components/structured-data"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import {
  compileArticleSEOFields,
  computeImageAttr,
  replaceImageBase,
} from "../util"

const wrapper = css`
  padding-bottom: 100px;
  padding-top: 4rem;
  background-color: #fafafa;
  @media (max-width: 767px) {
    padding-top: 2.5rem;
  }
  @media (max-width: 479px) {
    padding-bottom: 75px;
  }
`

const wrapperInner = css`
  margin-bottom: 3rem;
  max-width: 732px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 479px) {
    margin-bottom: 50px;
  }
`

const top = css`
  margin-bottom: 70px;
  @media (max-width: 479px) {
    margin-bottom: 2rem;
  }
`

const ArticleTemplate = ({ data, location }) => {
  const post = data.wordpressWpRedTeamChronicles
  const { siteUrl } = useSiteMetadata()
  let otherMetaTags = compileArticleSEOFields(post, siteUrl)
  const postContent = replaceImageBase(post.content)
  const [imageUrl, altText] = computeImageAttr(post)
  return (
    <PageLayout>
      <SEO
        yoastMeta={post.yoast_meta}
        title={post.title}
        ogType="article"
        extra={otherMetaTags}
        description={post.excerpt}
      />
      <BlogStructuredData
        post={post}
        siteUrl={siteUrl}
        pathname={location?.pathname ?? ""}
      />
      <div css={wrapper}>
        <div css={wrapperInner}>
          <div css={top}>
            <DraftLabel status={post.status} />
            <h1 css={article.title}>{post.title}</h1>
            <div css={article.topRow}>
              <p css={article.byLine}>by {post.author.name}</p>
              <div css={article.socialLinks}>
                <a
                  href={`https://twitter.com/intent/tweet/?text=${post.title}&url=https://smokescreen.io/blog/${post.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon width={24} height={24} />
                </a>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=https://smokscreen.io/blog/${post.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon width={24} height={24} />
                </a>
              </div>
            </div>
          </div>
          <If test={imageUrl}>
            <div css={article.imageWrapper}>
              <Img
                fluid={imageUrl}
                alt={altText}
                loading="eager"
                fadeIn={false}
              />
            </div>
          </If>
          <If test={post.acf?.subtitle}>
            <em
              dangerouslySetInnerHTML={{ __html: post.acf.subtitle }}
              css={article.subtitle}
            />
          </If>
          <div
            dangerouslySetInnerHTML={{ __html: postContent }}
            css={article.content}
          />
        </div>
        <Banner data={data} />
      </div>
    </PageLayout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query RedTeamArticleTemplate($id: String!) {
    wordpressWpRedTeamChronicles(id: { eq: $id }) {
      title
      content
      excerpt
      slug
      date
      modified
      status
      ...FeaturedMediaRedTeam
      author {
        id
        name
      }
      acf {
        subtitle
      }
      yoast_meta {
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_redirect
        yoast_wpseo_opengraph_title
        yoast_wpseo_opengraph_description
        yoast_wpseo_metakeywords
        yoast_wpseo_metadesc
        yoast_wpseo_meta_robots_noindex
        yoast_wpseo_meta_robots_nofollow
        yoast_wpseo_meta_robots_adv
        yoast_wpseo_linkdex
        yoast_wpseo_focuskw
        yoast_wpseo_canonical
      }
    }
    costSectionImage: file(relativePath: { eq: "homepage/cost-image-2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 530) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
